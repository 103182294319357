import { template as template_c684df9241de473e82196c40607e6865 } from "@ember/template-compiler";
const FKLabel = template_c684df9241de473e82196c40607e6865(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_9262cc68028d4e6fb2ff7f620e7f55d6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9262cc68028d4e6fb2ff7f620e7f55d6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_d068e57599554f54b28be7e37a30a67e } from "@ember/template-compiler";
const WelcomeHeader = template_d068e57599554f54b28be7e37a30a67e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
